import { useState } from 'react'
import { Switch } from 'antd'
import { SunOutlined, MoonOutlined } from '@ant-design/icons'
import { state } from '../store'

function DayNightSwitch() {

  const [isNight, setIsNight] = useState(true)

  const toggleNightMode = () => {
    console.log('[DayNightSwitch] toggleNightMode')
    setIsNight(!isNight)
    if (isNight) {
      // Change state.color to '#EFBD4E' (day mode)
      state.colour = '#EFBD4E'
    } else {
      // Change state.color to '#726DE8' or '#353934' (night mode)
      state.colour = '#353934'
    }
  }

  return (
    <>
      <Switch
        style={{background: isNight ? '#6100ed' : '#EFBD4E'}}
        checked={isNight}
        onChange={toggleNightMode}
        checkedChildren={<MoonOutlined />}
        unCheckedChildren={<SunOutlined />}
      />
    </>
  )
}

export default DayNightSwitch
