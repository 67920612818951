/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 survival.glb
*/

import React, {useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {useSnapshot} from "valtio/index";
import {state} from "../store";
import {easing} from "maath";
import {useFrame} from "@react-three/fiber";

export default function Survival(props) {
    const snap = useSnapshot(state)
    const group = useRef()
    const {nodes, materials} = useGLTF('./assets/survival.glb')
    useFrame((state, delta) => {
        let targetPosition = [0.3, -0.3, 0];
        if(!snap.intro) {
            targetPosition = [0, -0.3, 0];
        }
        easing.dampE(group.current.position, targetPosition, 0.25, delta)
    })
    return (
        <group {...props} ref={group} dispose={null}>
                <mesh receiveShadow castShadow geometry={nodes.Group004.geometry} material={materials['colormap.117']}
                      position={[-0.009, 0, 1.974]} scale={0.146}/>
                <mesh receiveShadow castShadow geometry={nodes.Group005.geometry} material={materials['colormap.117']}
                      position={[0.036, 0, 2.02]} scale={0.146}/>
                <mesh receiveShadow castShadow geometry={nodes.Group006.geometry} material={materials['colormap.117']}
                      position={[0.053, 0, 1.957]} scale={0.146}/>
                <mesh receiveShadow castShadow geometry={nodes.grass001.geometry} material={materials['colormap.091']}
                      position={[0.967, 0, -0.085]} scale={0.795}/>
                <mesh receiveShadow castShadow geometry={nodes['grass-large001'].geometry}
                      material={materials['colormap.090']} position={[-0.08, 0, -0.882]} scale={0.581}/>
                <mesh receiveShadow castShadow geometry={nodes['grass-large002'].geometry}
                      material={materials['colormap.096']} position={[0.583, 0, 1.211]} scale={0.598}/>
                <mesh receiveShadow castShadow geometry={nodes['grass-large003'].geometry}
                      material={materials['colormap.097']} position={[-0.68, 0, 1.569]}
                      rotation={[Math.PI, -0.789, Math.PI]} scale={0.598}/>
                <mesh receiveShadow castShadow geometry={nodes['patch-grass001'].geometry}
                      material={materials['colormap.089']} position={[-0.705, 0, 0.811]} scale={[1.593, 1, 1.569]}/>
                <mesh receiveShadow castShadow geometry={nodes['patch-grass002'].geometry}
                      material={materials['colormap.093']} position={[0.205, 0, -0.722]} scale={[1.593, 1, 1.569]}/>
                <mesh receiveShadow castShadow geometry={nodes['patch-grass003'].geometry}
                      material={materials['colormap.094']} position={[-1.456, 0, -1.338]}/>
                <mesh receiveShadow castShadow geometry={nodes['patch-grass-large001'].geometry}
                      material={materials['colormap.088']} position={[-1.456, 0, 0]}/>
                <mesh receiveShadow castShadow geometry={nodes['patch-grass-large002'].geometry}
                      material={materials['colormap.092']} position={[0.819, 0, 0.998]}/>
                <mesh receiveShadow castShadow geometry={nodes['patch-grass-large003'].geometry}
                      material={materials['colormap.095']} position={[0.569, 0, 2.245]}
                      rotation={[-Math.PI, 0.319, -Math.PI]}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-a001'].geometry}
                      material={materials['colormap.086']} position={[-0.851, 0.025, -0.462]}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-b001'].geometry}
                      material={materials['colormap.085']} position={[0.618, 0, 2.014]}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-c001'].geometry}
                      material={materials['colormap.084']} position={[-1.447, 0, -1.233]} rotation={[0, -1.29, 0]}
                      scale={[1, 1.343, 1]}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-flat001'].geometry}
                      material={materials['colormap.079']} position={[-0.06, 0, 0.025]} scale={[0.933, 1.639, 0.933]}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-flat002'].geometry}
                      material={materials['colormap.080']} position={[0.027, 0, 0.861]}
                      rotation={[Math.PI, -0.955, Math.PI]}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-flat-grass001'].geometry}
                      material={materials['colormap.087']} position={[-1.826, 0, 0.912]} rotation={[0, -1.248, 0]}
                      scale={0.571}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-sand-a001'].geometry}
                      material={materials['colormap.083']} position={[0.899, 0, 0.093]}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-sand-b001'].geometry}
                      material={materials['colormap.082']} position={[0.119, 0.227, -0.072]}
                      rotation={[-Math.PI, 0.521, -Math.PI]}/>
                <mesh receiveShadow castShadow geometry={nodes['rock-sand-c001'].geometry}
                      material={materials['colormap.081']} position={[-1.124, 0, 0]}/>
                <mesh receiveShadow castShadow geometry={nodes['grass-large004'].geometry}
                      material={materials['colormap.112']} position={[-0.917, 0, 0.257]}/>
                <mesh receiveShadow castShadow geometry={nodes['tree-trunk001'].geometry}
                      material={materials['colormap.098']} position={[-1.23, 0, 0.388]} scale={0.864}/>
                <mesh receiveShadow castShadow geometry={nodes['tree-tall001'].geometry}
                      material={materials['colormap.099']} position={[0.374, 0, -0.633]}/>
                <mesh receiveShadow castShadow geometry={nodes['tree-log-small001'].geometry}
                      material={materials['colormap.100']} position={[1.091, 0, 0.83]}
                      rotation={[-0.077, -0.58, -0.45]}/>
                <mesh receiveShadow castShadow geometry={nodes['tree-log001'].geometry}
                      material={materials['colormap.101']} position={[0.452, 0.278, 0.243]}
                      rotation={[-1.513, -1.347, -1.577]} scale={0.546}/>
                <mesh receiveShadow castShadow geometry={nodes['tree-autumn-trunk001'].geometry}
                      material={materials['colormap.102']} position={[-0.456, 0, 1.608]} scale={0.634}/>
                <mesh receiveShadow castShadow geometry={nodes['tree-autumn-tall001'].geometry}
                      material={materials['colormap.103']} position={[1.114, 0, 2.006]}/>
                <mesh receiveShadow castShadow geometry={nodes['tree-autumn001'].geometry}
                      material={materials['colormap.104']} position={[0.677, 0, -0.294]}/>
                <mesh receiveShadow castShadow geometry={nodes.tree001.geometry} material={materials['colormap.105']}
                      position={[-1.768, 0, -1.017]} rotation={[0, -1.559, 0]}/>
                <mesh receiveShadow castShadow geometry={nodes['tree-log002'].geometry}
                      material={materials['colormap.106']} position={[-0.48, 0.059, -0.743]}
                      rotation={[-0.147, -1.16, -0.128]} scale={0.546}/>
                <mesh receiveShadow castShadow geometry={nodes.tree002.geometry} material={materials['colormap.111']}
                      position={[0, 0, -0.72]} rotation={[0, -1.559, 0]}/>
                <mesh receiveShadow castShadow geometry={nodes['tent-canvas001'].geometry}
                      material={materials['colormap.107']} position={[0.31, 0.146, 0.643]} rotation={[0, -0.289, 0]}/>
                <mesh receiveShadow castShadow geometry={nodes['bedroll-packed001'].geometry}
                      material={materials['colormap.108']} position={[0.266, 0.154, 0.82]}/>
                <mesh receiveShadow castShadow geometry={nodes.bucket001.geometry} material={materials['colormap.109']}
                      position={[-0.137, 0.167, 0.893]} scale={0.8}/>
                <mesh receiveShadow castShadow geometry={nodes['campfire-pit001'].geometry}
                      material={materials['colormap.110']} position={[0, 0.163, 1.229]}/>
                <mesh receiveShadow castShadow geometry={nodes['bottle-large001'].geometry}
                      material={materials['colormap.113']} position={[0.355, 0.165, 0.453]}/>
                <mesh receiveShadow castShadow geometry={nodes.bottle001.geometry} material={materials['colormap.114']}
                      position={[0, 0.162, 0.875]}/>
                <mesh receiveShadow castShadow geometry={nodes.signpost001.geometry}
                      material={materials['colormap.115']} position={[-0.238, 0, 1.734]} rotation={[0, -0.41, 0]}/>
                <mesh receiveShadow castShadow geometry={nodes['flowers-tall'].geometry}
                      material={materials['colormap.118']} position={[-0.601, 0, 0.829]} scale={0.17}/>
                <mesh receiveShadow castShadow geometry={nodes['flowers-tall001'].geometry}
                      material={materials['colormap.120']} position={[0.154, 0, 2.027]} rotation={[0, -0.902, 0]}
                      scale={0.187}/>
                <mesh receiveShadow castShadow geometry={nodes['flowers-tall002'].geometry}
                      material={materials['colormap.121']} position={[-1.359, 0, -0.835]} scale={0.17}/>
                <mesh receiveShadow castShadow geometry={nodes.mushrooms.geometry} material={materials['colormap.119']}
                      position={[-1.119, 0, -1.352]} scale={0.216} />
                <mesh receiveShadow castShadow geometry={nodes.mushrooms001.geometry}
                      material={materials['colormap.122']} position={[-1.367, 0.426, -1.326]} scale={0.216}/>
                <mesh receiveShadow castShadow geometry={nodes.mushrooms002.geometry}
                      material={materials['colormap.123']} position={[-1.161, 0, 0.517]} rotation={[0, 0.589, 0]}
                      scale={0.216}/>
                <mesh receiveShadow castShadow geometry={nodes.Group007.geometry} material={materials['colormap.124']}
                      position={[0.631, 0.162, 0.853]} scale={0.146}/>
                <mesh receiveShadow castShadow geometry={nodes.Group008.geometry} material={materials['colormap.124']}
                      position={[0.614, 0.162, 0.915]} scale={0.146}/>
                <mesh receiveShadow castShadow geometry={nodes.Group009.geometry} material={materials['colormap.124']}
                      position={[0.568, 0.162, 0.869]} scale={0.146}/>
        </group>
    )
}

useGLTF.preload('./assets/survival.glb')
