import {useSnapshot} from 'valtio'
import {state} from "../store";
import {Intro} from "./Intro";
import {Explore} from "./Explore";
import DayNightSwitch from "./DayNightSwitch";
import {AnimatePresence, motion} from 'framer-motion'
export function Overlay() {
  const snap = useSnapshot(state)
    const transition = { type: 'spring', duration: 0.8 }
    const config = {
        initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0.5 } },
        animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
        exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
    }
  return (
      <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
          <motion.header
              initial={{opacity: 0, y: -100}}
              animate={{opacity: 1, y: 0}}
              transition={transition}
              style={{position: 'absolute', top: 0, left: 0, zIndex: 1}}
          >
              <div className={'logo gradient'}>hello.</div>
              <DayNightSwitch/>
          </motion.header>
          <AnimatePresence>
              {snap.intro && <motion.section key="main" {...config}>
                  <Intro/>
              </motion.section>}
              {!snap.intro && <motion.section key="explore" {...config}>
                  <Explore/>
              </motion.section>}
          </AnimatePresence>
      </div>
  )
}
