
import {Scene} from "./Scene";
import {Overlay} from "./Overlay";
function App() {
  return (
      <>
          <Scene />
          <Overlay />
      </>
  )
}

export default App
