import { motion } from 'framer-motion'
import { useSnapshot } from 'valtio'
import {state} from "../store";
import {ArrowRightOutlined} from "@ant-design/icons";

export function Intro() {
  const snap = useSnapshot(state)
  return (
      <div className="section--container">
          <motion.div
              key="title"
              initial={{x: -100, opacity: 0}}
              animate={{x: 0, opacity: 1}}
              transition={{type: 'spring', damping: 10, stiffness: 30, restDelta: 0.001, duration: 0.3}}>
              <h1 className={'gradient'}>hellostudios.</h1>
              <p className={'tagline'}>Creating amazing digital experiences</p>
          </motion.div>

          <div className="support--content">
              <motion.div
                  key="p"
                  initial={{y: 100, opacity: 0}}
                  animate={{y: 0, opacity: 1}}
                  transition={{
                      type: 'spring',
                      damping: 7,
                      stiffness: 30,
                      restDelta: 0.001,
                      duration: 0.6,
                      delay: 0.2,
                      delayChildren: 0.2
                  }}>
                  <p>
                      Bringing ideas to life through expert web development, intuitive user experiences, and stunning
                      design. We blend creativity with technology to deliver seamless, user-focused solutions.
                  </p>
                  <p className={'hello '}>Say <a className={'email'} href={'mailto:hello@hellostudios.com.au'}>hello@hellostudios.com.au</a>
                  </p>

                  <button style={{background: snap.colour}} onClick={() => (state.intro = false)}>
                      Explore <ArrowRightOutlined size="1.3em"/>
                  </button>
              </motion.div>
          </div>
      </div>
  )
}
