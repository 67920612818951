import {useRef} from 'react'
import {Canvas, useFrame} from '@react-three/fiber'
import {
    SoftShadows,
    ContactShadows,
    OrbitControls, Backdrop, Sparkles
} from '@react-three/drei'
import {easing} from 'maath'
import {useSnapshot} from 'valtio'
import {state} from '../store'
import Survival from "./Survival";

export const Scene = ({position = [0, 1, 3], fov = 30}) => {
    const snap = useSnapshot(state)
    return (
        <Canvas shadows camera={{position, fov, near: 0.1, far: 10}} gl={{preserveDrawingBuffer: true}}
                eventSource={document.getElementById('root')} eventPrefix="client">
            {/*<ambientLight intensity={0.6 * Math.PI}/>*/}
            <SoftShadows size={10} samples={16}/>
            <ContactShadows frames={1} position={[0, -0.83, 0]} scale={10} opacity={0.4} far={1} blur={2}/>

            <Background/>
            <OrbitControls
                minPolarAngle={Math.PI / 3}
                maxPolarAngle={Math.PI / 2}
                minAzimuthAngle={-Math.PI / 8}
                maxAzimuthAngle={Math.PI / 6}
                enableZoom={false}
                minZoom={0}
                maxZoom={0}/>
            <Sparkles
                size={2}
                scale={1}
                position-y={0}
                speed={0.2}
                count={20}
                noise={40}
                color={'#EFBD4E'}
            />
            <Survival position={[0.3, -0.3, 0]} scale={0.3} rotation={[0, Math.PI / 6, 0]}/>
            <directionalLight
                position={[-5, 5, 5]}
                intensity={3}
                castShadow
                shadow-mapSize-width={2048}
                shadow-mapSize-height={2048}
                shadow-bias={-0.0001}
                color={(snap.colour === '#EFBD4E' ? '#8f2d07' : '#6100ed')}
            />
            <directionalLight position={[5, 5, 5]} intensity={2}/>
        </Canvas>
    )
}

function Background() {
    const snap = useSnapshot(state)
    const colour = useRef()
    useFrame((state, delta) => {
        return easing.dampC(colour.current.color, snap.colour, 0.25, delta)
    })
    return (
        <Backdrop scale={[40, 5, 2]} floor={4} position={[0, -0.31, -2]} receiveShadow>
            <meshStandardMaterial ref={colour} color={state.colour}/>
        </Backdrop>
    )
}

function CameraRig({children}) {
    const group = useRef()
    const snap = useSnapshot(state)
    useFrame((state, delta) => {
        easing.damp3(state.camera.position, [snap.intro ? -state.viewport.width / 4 : 0, 0, 5], 0.25, delta)
        easing.dampE(group.current.rotation, [state.pointer.y / 10, -state.pointer.x / 5, 0], 0.25, delta)
    })
    return <group ref={group}>{children}</group>
}
