import { Button } from 'antd'
import { useSnapshot } from 'valtio'
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons'
import {state} from "../store";

export function Explore() {
  const snap = useSnapshot(state)
  return (
      <div className="customizer">
          <button style={{background: snap.colour}} onClick={() => (state.intro = true)}>
              Back <ArrowLeftOutlined size="1.3em"/>
          </button>
      </div>
  )
}
